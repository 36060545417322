import React, { useEffect, useState } from 'react';
import {
    iconNhanHieu,
    iconTaiXuong,
    iconHopTac,
    iconPhoBien,
    iconTrangMang,
    iconDichVu,
    iconDocQuyen,
    iconNguoiMoi,
    iconVietNam,
    iconNguoiDungOnline,
} from '../../../../img/importImg';
import { Link } from 'react-router-dom';

function CenterHeader() {
    const [nowDay, setNowDay] = useState();
    const [nowTime, setNowTime] = useState();
    const [isDropbox, setIsDropox] = useState(false);
    const newDate = new Date();

    function formatDate(date) {
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        return `Ngày ${day} Tháng ${month} Năm ${year}`;
    }

    function formatTime(date) {
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');

        return `${hours}:${minutes}:${seconds}`;
    }

    useEffect(() => {
        const interval = setInterval(() => {
            const newDate = new Date(); // Lấy thời gian hiện tại
            setNowDay(formatDate(newDate));
            setNowTime(formatTime(newDate));
        }, 1000);

        return () => clearInterval(interval); // Xóa interval khi component bị unmount
    }, []);

    return (
        <div className="center-header relative">
            <div className="container">
                <ul>
                    {/* <li>
            <a href="/about">
              <span className="title">
                <img src={iconNhanHieu} alt="" />
                Nhãn hiệu
              </span>
              Giới Thiệu
            </a>
            <Link to="https://api.268bet.com/static/268bet.apk" target="_blank">
              <span className="title">
                <img src={iconTaiXuong} alt="" />
                Tải xuống
              </span>
              Ứng Dụng Di Động
            </Link>
          </li> */}
                    <li>
                        <a href="/">
                            <span className="title">
                                <img
                                    src={iconHopTac}
                                    alt=""
                                />
                                Hợp tác
                            </span>
                            Đại Lý
                        </a>
                        <a href="/">
                            <span className="title">
                                <img
                                    src={iconPhoBien}
                                    alt=""
                                />
                                Phổ biến
                            </span>
                            Trò Chơi Xổ Số
                        </a>
                    </li>
                    <li>
                        <div
                            onClick={() => setIsDropox(!isDropbox)}
                            style={{
                                textTransform: 'capitalize',
                                display: 'flex',
                                alignItems: 'center',
                                fontSize: '1.3rem',
                                padding: '0.5rem 0',
                            }}
                        >
                            <span
                                className="title"
                                style={{
                                    textTransform: 'initial',
                                    color: '#efde8e',
                                    display: 'inline-flex',
                                    alignItems: 'center',
                                    marginRight: '1rem',
                                }}
                            >
                                <img
                                    src={iconTrangMang}
                                    alt=""
                                    style={{ marginRight: '0.8rem' }}
                                />
                                Trang mạng
                            </span>
                            Link Dự Phòng
                        </div>
                        <a href="/">
                            <span className="title">
                                <img
                                    src={iconDichVu}
                                    alt=""
                                />
                                Dịch vụ
                            </span>
                            Chăm sóc khách hàng
                        </a>
                    </li>
                    <li>
                        <a href="/">
                            <span className="title">
                                <img
                                    src={iconDocQuyen}
                                    alt=""
                                />
                                Độc quyền
                            </span>
                            Xổ Số Trực Tiếp
                        </a>
                        <a href="/">
                            <span className="title">
                                <img
                                    src={iconNguoiMoi}
                                    alt=""
                                />
                                Người mới
                            </span>
                            Tài khoản dùng thử
                        </a>
                    </li>
                    <li>
                        <a href="/">
                            <span className="title">Giờ Hà Nội</span>
                            <span className="day">{nowDay}</span>
                            <span
                                className="hour"
                                style={{ marginBottom: '0.5px' }}
                            >
                                {nowTime}
                            </span>
                            <span className="country">
                                <img
                                    src={iconVietNam}
                                    alt=""
                                />
                            </span>
                        </a>
                        <a href="/">
                            <span className="title">Người dùng trực tuyến</span>
                            <div className="count-online">
                                <img
                                    src={iconNguoiDungOnline}
                                    alt=""
                                />
                                <div className="box-count">
                                    <span>3</span>
                                    <span>0</span>
                                    <span>6</span>
                                    <span>7</span>
                                    <span>9</span>
                                    <span>6</span>
                                </div>
                            </div>
                        </a>
                    </li>
                </ul>
            </div>

            {isDropbox && (
                <div
                    className="d-flex flex-column"
                    style={{
                        position: 'absolute',
                        top: '40px',
                        left: '40%',
                        width: '200px',
                        height: '50px',
                        borderRadius: '5px',
                        padding: '5px 30px',
                        backgroundColor: '#f6f6f6',
                        color: 'black',
                    }}
                >
                    <Link
                        to={'https://268bet.vip/'}
                        target="_blank"
                    >
                        https://268bet.vip
                    </Link>

                    <Link
                        to={'https://268bet.bet/'}
                        target="_blank"
                    >
                        https://268bet.bet
                    </Link>
                </div>
            )}
        </div>
    );
}

export default CenterHeader;
