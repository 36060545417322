import useWebSocket from 'react-use-websocket';
import './xoso.scss';
import { webSocketUrl } from '../../dummy';
import { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import {
    BetContent,
    getHistoryBet,
    getHistoryPhien,
    getNextPhien,
    getRateBet,
    Header,
    HistoryBet,
    Result,
} from './_component';
import { useParams } from 'react-router-dom';

const msgType = { successLogin: 'Đăng nhập thành công', successBet: 'Cược thành công!' };

export default function XoSo() {
    const {
        sendJsonMessage: sendMsg,
        lastJsonMessage: data,
        readyState,
    } = useWebSocket(webSocketUrl, {
        shouldReconnect: () => true,
    });
    const [isAuth, setIsAuth] = useState(false);
    const { id } = useParams();

    useEffect(() => {
        if (readyState === 1)
            sendMsg({
                auth: { token: sessionStorage.getItem('auth') },
            });
    }, [readyState]);

    useEffect(() => {
        if (data) {
            if (data.notice && data.notice.msg) {
                const msg = data.notice.msg;
                if (msg === msgType.successLogin) setIsAuth(true);
                else toast(data.notice.msg);
                if (msg === msgType.successBet) getHistoryBet(sendMsg, id, 1);
            }
            if (data?.xoso?.[id]?.kq) {
                setTimeout(() => {
                    if (isAuth) {
                        getHistoryPhien(sendMsg, id);
                        getHistoryBet(sendMsg, id, 1);
                    }
                }, 3000);
            }
        }
    }, [data]);

    useEffect(() => {
        console.log(isAuth);
        if (isAuth) {
            if (isNaN(id)) getNextPhien(sendMsg, id);
            getHistoryPhien(sendMsg, id);
            getHistoryBet(sendMsg, id, 1);
            getRateBet(sendMsg);
        }
    }, [id, isAuth]);

    return (
        <>
            <div className="bg-game">
                <div className="content-view">
                    <div className="game-container">
                        <div className="game-log-other">
                            <div className="play-wrap">
                                <Header {...{ data: data?.xoso, sendMsg }} />
                                <BetContent {...{ data: data?.xoso, sendMsg, id }} />
                            </div>
                            <Result {...{ data: data?.xoso, sendMsg, id }} />
                        </div>
                        <HistoryBet {...{ data: data?.xoso, sendMsg, id, isAuth }} />
                    </div>
                </div>
            </div>
            <ToastContainer
                position="top-right"
                autoClose={1000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </>
    );
}
