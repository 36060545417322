import React from 'react';

export default function Bet({
    kieuNhap,
    isHiddenTram,
    chuSo,
    tram,
    handleTram,
    handleClickXoaTram,
    isHiddenChuc,
    chuc,
    handleChuc,
    handleClickXoaChuc,
    isHiddenDonVi,
    donVi,
    handleDonVi,
    handleClickXoaDonVi,
    changeTextArea,
    textareaRef,
    cacSoCuoc,
    handlePlayExplainClick,
    playExplainRef,
    contentCachChoi,
}) {
    return (
        <>
            {kieuNhap === 'chonso' ? (
                <div className="play-nums">
                    {!isHiddenTram && (
                        <div className="play-item play-num">
                            <div className="num-bit-contain">
                                <div className="play-tag num-bit num-bit--vi">
                                    <span>Trăm</span>
                                    <div className="tag-bg"></div>
                                </div>
                                <div className="balls_wrap">
                                    <div className="balls">
                                        {chuSo.map((item, i) => {
                                            return (
                                                <div
                                                    className={`num ${tram.includes(item.value) ? 'active' : ''}`}
                                                    key={i}
                                                    onClick={() => handleTram(item.value)}
                                                >
                                                    <div>{item.title}</div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                    {/* <div className="omission"></div> */}
                                </div>
                                <div
                                    className="opts"
                                    onClick={handleClickXoaTram}
                                >
                                    <div className="opt-item">Xoá</div>
                                </div>
                            </div>
                        </div>
                    )}

                    {!isHiddenChuc && (
                        <div className="play-item play-num">
                            <div className="num-bit-contain">
                                <div className="play-tag num-bit num-bit--vi">
                                    <span>Chục</span>
                                    <div className="tag-bg"></div>
                                </div>
                                <div className="balls_wrap">
                                    <div className="balls">
                                        {chuSo.map((item, i) => {
                                            return (
                                                <div
                                                    className={`num ${chuc.includes(item.value) ? 'active' : ''}`}
                                                    key={i}
                                                    onClick={() => handleChuc(item.value)}
                                                >
                                                    <div>{item.title}</div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                    {/* <div className="omission"></div> */}
                                </div>
                                <div
                                    className="opts"
                                    onClick={handleClickXoaChuc}
                                >
                                    <div className="opt-item">Xoá</div>
                                </div>
                            </div>
                        </div>
                    )}

                    {!isHiddenDonVi && (
                        <div className="play-item play-num">
                            <div className="num-bit-contain">
                                <div className="play-tag num-bit num-bit--vi">
                                    <span>Đơn vị</span>
                                    <div className="tag-bg"></div>
                                </div>
                                <div className="balls_wrap">
                                    <div className="balls">
                                        {chuSo.map((item, i) => {
                                            return (
                                                <div
                                                    className={`num ${donVi.includes(item.value) ? 'active' : ''}`}
                                                    key={i}
                                                    onClick={() => handleDonVi(item.value)}
                                                >
                                                    <div>{item.title}</div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                    {/* <div className="omission"></div> */}
                                </div>
                                <div
                                    className="opts"
                                    onClick={handleClickXoaDonVi}
                                >
                                    <div className="opt-item">Xoá</div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            ) : (
                <div className="play-textarea">
                    <textarea
                        className="textarea-warp"
                        cols={30}
                        rows={9}
                        onChange={(e) => changeTextArea(e)}
                        ref={textareaRef}
                        value={cacSoCuoc}
                    ></textarea>

                    {!cacSoCuoc && (
                        <div
                            className="play-explain"
                            onClick={handlePlayExplainClick}
                            ref={playExplainRef}
                        >
                            Cách chơi:
                            <br />
                            <span>Giữa mỗi số cược cần phân cách nhau bởi dấu , hoặc cách nhau bởi khoảng trống</span>
                            <br />
                            <span>Ví dụ: {contentCachChoi && contentCachChoi.placeholder}</span>
                        </div>
                    )}
                </div>
            )}
        </>
    );
}
