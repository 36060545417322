import React from 'react';
import { isValidFormat, VND } from '../../../utils';
import { toast } from 'react-toastify';

export default function BetItem({
    id,
    sendMsg,
    theloai,
    contentCachChoi,
    cacSoCuoc,
    setCacSoCuoc,
    setTram,
    setChuc,
    setDonVi,
    isRender,
    setIsRender,
    daChon,
    setDaChon,
    soNhan,
    setSoNhan,
    thongTinDatCuoc,
    setThongTinDatCuoc,
    type,
    kieuNhap,
}) {
    const handleDatCuoc = () => {
        let bet = thongTinDatCuoc ?? null;
        if (bet === null || !soNhan || !contentCachChoi.value) {
            return toast.error(`Nội dung đặt cược không hợp lệ!`, {
                theme: 'light',
            });
        }

        const txcl = [
            { label: 'Tài', value: 'tai' },
            { label: 'Xỉu', value: 'xiu' },
            { label: 'Chẵn', value: 'chan' },
            { label: 'Lẻ', value: 'le' },
        ];
        const checkType = txcl.find((item) => item.label === thongTinDatCuoc);
        if (checkType) bet = checkType.value;

        const dataCuoc = {
            so: String(bet),
            sodiem: soNhan,
            type: contentCachChoi.value,
        };
        handleBet(dataCuoc);
        handleReset();
    };

    const handleSoNhanChange = (e) => {
        setSoNhan(Number(e.target.value));
    };
    const handleXacNhan = () => {
        let isValid = true;
        let daChonNew = 0;

        if (!cacSoCuoc)
            return toast.error(`Bạn chưa chọn số, vui lòng chọn số muốn cược`, {
                theme: 'light',
            });

        const cacSoCuocNew = cacSoCuoc.trim().replace(/\s/g, ',');

        switch (type) {
            case 'lo2':
            case 'de':
            case 'daude':
                isValid = isValidFormat(cacSoCuocNew, /^(\d{2,},)*\d{2,}$/);
                daChonNew = cacSoCuocNew.split(',').length;
                break;
            case 'xien2':
                isValid = isValidFormat(cacSoCuocNew, /^(\d{2}),(?!\1)(\d{2})$/);
                daChonNew = 1;
                break;
            case 'xien3':
                isValid = isValidFormat(cacSoCuocNew, /^(\d{2}),(?!\1)(\d{2}),(?!\1|\2)(\d{2})$/);
                daChonNew = 1;
                break;
            case 'xien4':
                isValid = isValidFormat(cacSoCuocNew, /^(\d{2}),(?!\1)(\d{2}),(?!\1|\2)(\d{2}),(?!\1|\2|\3)(\d{2})$/);
                daChonNew = 1;
                break;
            case 'dau':
            case 'duoi':
                isValid = isValidFormat(cacSoCuocNew, /^(\d,)*\d$/);
                daChonNew = cacSoCuocNew.split(',').length;
                break;
            case '3cang':
                isValid = isValidFormat(cacSoCuocNew, /^(\d{3},)*\d{3}$/);
                daChonNew = cacSoCuocNew.split(',').length;
                break;
            default:
                return;
        }

        if (!isValid) {
            return toast.error(`Vui lòng nhập lại theo đúng định dạng ${contentCachChoi.placeholder} !`, {
                theme: 'light',
            });
        }

        setThongTinDatCuoc(cacSoCuocNew);
        setDaChon(daChonNew);
    };

    const handleBet = (childData) => {
        if (!childData) return;
        childData.theloai = id;
        if (id.includes('xsmn-')) childData.location = id.split('-')[1];
        sendMsg({ xoso: { cuoc: childData } });
    };

    const handleReset = () => {
        setTram([]);
        setChuc([]);
        setDonVi([]);
        setDaChon(0);
        setSoNhan(1);
        setThongTinDatCuoc('');
        setCacSoCuoc('');
        setIsRender(!isRender);
    };

    return (
        <div className="bet-container">
            <div className="bet-input">
                <div className="bet-info">
                    {theloai !== 'txcl' && (
                        <div className="input-odds">
                            Tỉ lệ cược : <span>1</span> : <span>{contentCachChoi?.tiLe / 1000}</span>
                        </div>
                    )}
                    <div className="ml-4">
                        Đã chọn : <span>{daChon}</span>
                    </div>
                    <div className="ml-4">
                        Số tiền : <span>{VND.format(daChon * contentCachChoi.minCuoc * soNhan)}</span>
                    </div>
                </div>
                <div className="bet-btns">
                    <span>
                        <input
                            className="basic-slide"
                            id="so-nhan"
                            type="number"
                            value={soNhan}
                            onChange={(e) => handleSoNhanChange(e)}
                        />
                        <label htmlFor="name">Số nhân</label>
                    </span>

                    {kieuNhap === 'nhapso' && (
                        <div
                            className="bet-btn btn-add"
                            onClick={handleXacNhan}
                        >
                            Xác nhận
                        </div>
                    )}

                    <div
                        className="reset"
                        onClick={handleReset}
                    >
                        Cài đặt lại
                    </div>
                </div>
            </div>
            <div className="bets">
                <div className="bets-box">
                    <div className="table-title">Nội dung cược</div>
                    <div className="item table-name row">
                        <div className="col-2">Cách chơi</div>
                        <div className="col-4">Thông tin đặt cược</div>
                        <div className="col-1">Số đơn</div>
                        <div className="col-1">Số nhân</div>
                        <div className="col-2">Số tiền cược</div>
                        <div className="col-2">Tiền thắng/1 lần</div>
                        {/* <div className="col-2">Thao tác</div> */}
                    </div>
                    <div className="b-content">
                        {daChon === 0 ? (
                            <div className="no-data">
                                <p>Không có dữ liệu</p>
                            </div>
                        ) : (
                            <div className="item table-name row content-table">
                                <div className="col-2">{contentCachChoi && contentCachChoi.title}</div>
                                <div className="col-4">{daChon !== 0 && thongTinDatCuoc}</div>
                                <div className="col-1">1</div>
                                <div className="col-1">{soNhan}</div>
                                <div className="col-2">{VND.format(daChon * contentCachChoi.minCuoc * soNhan)}</div>
                                <div className="col-2">{VND.format(contentCachChoi?.tiLe * soNhan)}</div>
                                {/* <div className="col-2">Chờ xác nhận</div> */}
                            </div>
                        )}
                    </div>
                </div>
                <div className="bet-btns d-flex align-items-center justify-content-between mx-5 mt-4">
                    <div className="total">
                        Tổng số tiền : <span>{VND.format(daChon * contentCachChoi.minCuoc * soNhan)}</span>
                    </div>
                    <div className="d-flex">
                        <div
                            className="bet-btn btn-detel big mr-2"
                            onClick={handleReset}
                        >
                            Xoá hết
                        </div>
                        {daChon !== 0 && (
                            <div
                                className="bet-btn btn-submit big"
                                onClick={handleDatCuoc}
                            >
                                Đặt cược
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
