import React, { useEffect, useState } from 'react';
import { loadDot, mienbac, st1p, st3p, st5p, st10p, st15p, st20p, st30p } from '../../../img/importImg';
import 'react-toastify/dist/ReactToastify.css';
import { useParams } from 'react-router-dom';
import { getTimeLeft, formatSeconds } from '../../../utils';
import { navbar } from '../../../components/Layout/Navbar/data';
import dayjs from 'dayjs';

const ballGdb = [0, 1, 2, 3, 4];

const xsstImg = {
    1: st1p,
    3: st3p,
    5: st5p,
    10: st10p,
    15: st15p,
    20: st20p,
    30: st30p,
};

export default function Header({ data, sendMsg }) {
    const [time, setTime] = useState(0);
    const [phien, setPhien] = useState(0);
    const [timeLeft, setTimeLeft] = useState(formatSeconds(0));
    const [moGiai, setMoGiai] = useState(false);
    const [noiDungTheLoai, setNoiDungTheLoai] = useState({
        title: '',
        img: '',
    });
    const [totalTime, setTotalTime] = useState(61);
    const [timeEnd, setTimeEnd] = useState(Date.now());

    const [lastResult, setLastResult] = useState({ gdb: [''], phien: '' });

    const { id } = useParams();

    useEffect(() => {
        if (!isNaN(id)) {
            setNoiDungTheLoai({ title: `Siêu tốc ${id} Phút`, img: xsstImg[id] });
            setTotalTime(Number(id) * 60);
        }

        if (id === 'xsmb') setNoiDungTheLoai({ title: 'Xổ Số Miền Bắc', img: mienbac });

        if (id.includes('xsmn-')) {
            const info = navbar.miennam[8].find((item) => item.link.includes(id));
            setNoiDungTheLoai(info);
        }
        if (id.includes('xsmt-')) {
            const info = navbar.mientrung[8].find((item) => item.link.includes(id));
            setNoiDungTheLoai(info);
        }
    }, [id]);

    useEffect(() => {
        if (id === 'xsmb') {
            const timer = setInterval(() => {
                setTimeLeft(getTimeLeft());
            }, 1000);
            return () => clearInterval(timer);
        }
    }, [id]);
    useEffect(() => {
        let timer;
        if (isNaN(id)) {
            timer = setInterval(() => {
                setTimeLeft(formatSeconds((timeEnd - Date.now()) / 1000));
                if (moGiai) clearInterval(timer);
            }, 1000);
        }
        return () => {
            if (timer) clearInterval(timer);
        };
    }, [id, timeEnd, moGiai]);

    useEffect(() => {
        if (data !== null) {
            if (data && data[id]) {
                if (data[id].lastResult) {
                    setLastResult(data[id].lastResult);
                }

                if (data[id].time) {
                    setTimeLeft(formatSeconds(data[id].time));
                    setTime(data[id].time);
                }

                if (data[id].nextPhien && isNaN(id)) {
                    const { phien, timeEnd } = data[id].nextPhien;
                    if (phien === false) {
                        setPhien(dayjs().format('YYYYMMDD'));
                        setMoGiai(true);
                    } else {
                        setMoGiai(false);
                        setPhien(phien);
                        setTimeEnd(timeEnd);
                        setTime(timeEnd - Date.now());
                    }
                }

                if (data[id].phien) setPhien(data[id].phien);
            }
        }
    }, [data, id]);

    useEffect(() => {
        if (!isNaN(id)) {
            if (time === 0 || time === totalTime || time === totalTime - 1) {
                setMoGiai(true);
            } else setMoGiai(false);
        }
    }, [time]);

    return (
        <div className="game-info">
            <div className="game-name">
                <div className="img-box">
                    <img
                        src={noiDungTheLoai.img}
                        alt=""
                    />
                </div>
                <div>
                    <h3>{noiDungTheLoai.title}</h3>
                </div>
            </div>
            <div className="game-num">
                <div className="balls">
                    {ballGdb.map((item) => (
                        <div
                            key={item}
                            className="ball"
                        >
                            <span>
                                {moGiai ? (
                                    <img
                                        src={loadDot}
                                        alt=""
                                    />
                                ) : (
                                    String(lastResult.gdb[0]).charAt(item)
                                )}
                            </span>
                        </div>
                    ))}
                    {(id.includes('xsmn-') || id.includes('xsmt-')) && (
                        <div className="ball">
                            {moGiai ? (
                                <span>
                                    <img
                                        src={loadDot}
                                        alt=""
                                    />
                                </span>
                            ) : (
                                <span>{String(lastResult.gdb[0]).charAt(5)}</span>
                            )}
                        </div>
                    )}
                </div>
                <span>Kết quả mở thưởng {lastResult.phien}</span>
            </div>
            <div className="pc-game-time">
                <span>Dừng cược {phien}</span>
                {!moGiai ? (
                    <div className="count">
                        <div className="item">
                            {timeLeft.hours.map((item, i) => (
                                <span key={i}>{item}</span>
                            ))}
                        </div>
                        <div className="dot"></div>
                        <div className="item">
                            <span>{timeLeft.minutes[0]}</span>
                            <span>{timeLeft.minutes[1]}</span>
                        </div>
                        <div className="dot"></div>
                        <div className="item">
                            <span>{timeLeft.seconds[0]}</span>
                            <span>{timeLeft.seconds[1]}</span>
                        </div>
                    </div>
                ) : (
                    <div className="count">Đang mở giải</div>
                )}
            </div>
        </div>
    );
}
