import React, { useEffect, useState } from 'react';
import { bgLotte, iconDown, iconUp } from '../../../img/importImg';

const dauduoi = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
const result = {
    gdb: 'Giải ĐB',
    g1: 'Giải nhất',
    g2: 'Giải nhì',
    g3: 'Giải ba',
    g4: 'Giải tư',
    g5: 'Giải năm',
    g6: 'Giải sáu',
    g7: 'Giải bảy',
};

export default function Result({ data, sendMsg, id }) {
    const [isModalList, setIsModalList] = useState(false);
    const [selected, setSelected] = useState();
    const [history, setHistory] = useState();
    const handleClickSelected = (valueSelected) => {
        setSelected(valueSelected);
        setIsModalList(false);
    };

    useEffect(() => {
        if (data && data[id]) {
            if (data[id].getphien) {
                const result = data[id].getphien.data;
                setHistory(result);
                setSelected(result[0]);
            }
        }
    }, [data]);

    return (
        <div className="history-wrap">
            <div className="game-history vncp27">
                <div className="result-web">
                    <img
                        src={bgLotte}
                        alt=""
                    />
                </div>
                <div
                    className="h-select"
                    onClick={() => setIsModalList(!isModalList)}
                >
                    <div className="el-select">
                        <div className="el-input el-input--suffix">
                            <input
                                type="text"
                                readOnly="readonly"
                                autoComplete="off"
                                className="el-input__inner"
                                value={selected?.phien || ''}
                            />
                            <img
                                src={isModalList ? iconUp : iconDown}
                                alt=""
                                style={{ width: '20px', height: '20px' }}
                            />
                        </div>

                        {isModalList && (
                            <div className="el-select-dropdown el-popper">
                                <div className="el-scrollbar">
                                    <div className="el-select-dropdown__wrap el-scrollbar__wrap">
                                        <ul className="el-scrollbar__view el-select-dropdown__list">
                                            {history &&
                                                history.map((item, i) => {
                                                    return (
                                                        <li
                                                            className={`el-select-dropdown__item ${
                                                                selected?.id === item.id ? 'selected' : ''
                                                            }`}
                                                            key={i}
                                                            onClick={() => handleClickSelected(item)}
                                                        >
                                                            {item.phien}
                                                        </li>
                                                    );
                                                })}
                                        </ul>
                                    </div>
                                </div>
                                <div className="popper__arrow"></div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="history-vncp pc-history-vncp">
                    <div className="cur-item">Kỳ số {selected?.kiSo}</div>
                    <ul className="list results1">
                        {Object.keys(result).map((key) => (
                            <li key={key}>
                                <div>{result[key]}</div>
                                <div>{selected?.[key].join(', ')}</div>
                            </li>
                        ))}
                        {selected?.g8?.length > 0 && (
                            <li>
                                <div>Giải tám</div>
                                <div>{selected?.g8.join(', ')}</div>
                            </li>
                        )}
                    </ul>
                    <ul className="list results2">
                        <li className="title">
                            <div>Đầu</div>
                            <div>Đuôi</div>
                        </li>
                        {dauduoi.map((num) => (
                            <li key={num}>
                                <div>{num}</div>
                                <div>{selected && selected.dauduoi[num]}</div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
}
