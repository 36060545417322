import React, { useEffect } from "react";
import "./newsrun.scss";
import { thongBao } from "../../img/importImg";
import withdrawAPI from "../../API/withdrawAPI";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useState } from "react";

function NewsRun() {
  const [settings, setSettings] = useState();

  useEffect(() => {
    try {
      const fetchGetSetting = async () => {
        const result = await withdrawAPI.getSetting();
        // console.log(result.data[0]);

        if (result.status === true) {
          setSettings(result.data[0]);
        } else {
          return toast.error(result.msg, { theme: "light" });
        }
      };

      fetchGetSetting();
    } catch (error) {
      toast.error("Có lỗi xảy ra", { theme: "light" });
    }
  }, []);

  return (
    <div className="home-news-run">
      <div className="container">
        <div className="wrap-run">
          <h3 className="title">
            <img src={thongBao} alt="" />
          </h3>

          <div className="wrapper">
            <p className="target">
              XIN THÔNG BÁO NÂNG CẤP CỘNG NẠP 24/7： Để nâng cao bảo mật thông
              tin khách hàng trên cổng nạp 24/7 hiện nay có 1 số ngân hàng áp
              dụng phương thức chuyển khoản điền nội dung giao dịch theo mã xác
              minh ngẫu nhiên được hiện thị trên cộng nạp ,quý khách chỉ cần
              copy phần mã xác minh và dán vào phần nội dung chuyển khoản sau đó
              điền đúng số tiền mình đã chuyển và bấm xác nhận nạp tiền hệ thống
              sẽ tự động lên điểm nhé ạ！ LƯU Ý : khi thanh toán phương thức
              điền mã xác minh quý khách vui lòng điền đúng mã xác minh để tránh
              tình trạng khoản nạp không được lên điểm quý khách cần điền đúng
              mã xác minh khi chuyển tiền ! ⚠️【TỰ ĐỘNG CHUYỂN QUỸ VÀO
              SẢNH】⚠️： Quý khách thân mến! Để thuận tiện cho quá trình trải
              nghiệm game tốc độ siêu nhanh, bắt đầu từ ngày 18/2/2023{" "}
              {settings && settings.name_page}
              sẽ tiến hành nâng cấp【TỰ ĐỘNG CHUYỂN QUỸ VÀO SẢNH】. Quý khách
              truy cập vào bất cứ sảnh thứ ba quỹ tiền của quý khách sẽ được tự
              động chuyển vào sảnh đó. Khi quý khách rời sảnh quỹ tiền sẽ được
              tự động chuyển về sảnh chính và có thể lập tức rút tiền. Cảm ơn
              quý khách đã tin tưởng và ủng hộ. Chúc quý khách có những trải
              nghiệm thật tuyệt vời tại {settings && settings.name_page}~~
              ⚠️CẢNH BÁO LỪA ĐẢO ⚠️： Gần đây có nhiều đối tượng mạo danh ĐẠI LÝ
              hợp tác với nhà cái
              {settings && settings.name_page} bên em để lừa các thành viên nhẹ
              dạ cả tin bằng những lời dụ dỗ như nạp tiền sẽ nhận khuyến mãi
              200% hoặc 100% chỉ yêu cầu 1 vòng cược , khi chuyển khoản nạp tiền
              đối tượng sẽ yêu cầu điền nội dung mà đối tượng cung cấp (thường
              sẽ là tên tài khoản đăng nhập của đối tượng lừa đảo) sau khi
              chuyển khoản thành công đối tượng sẽ yêu cầu thành viên cung cấp
              ảnh chuyển khoản sẽ hỗ trợ lên điểm và khuyến mãi ,nhưng đối tượng
              này sẽ dùng ảnh chuyển khoản trên để liên hệ cskh của{" "}
              {settings && settings.name_page} bên em và yêu cầu lên điểm vào
              tài khoản của đối tượng lừa đảo nhằm chiếm đoạt khoản nạp tiền của
              thành viên . ⚠️LƯU Ý : Các khuyến mãi của{" "}
              {settings && settings.name_page} sẽ được cập nhật đầy đủ ở trang
              chủ quý khách vui lòng truy cập trang chủ để tham khảo các khuyến
              mãi mới nhất và chính xác nhất của bên em nhé . ⚠️Đặc biệt : Cách
              thức nạp tiền quý khách vui lòng truy cập trang chủ{" "}
              {settings && settings.name_page} đến mục Nạp Tiền để lấy thông tin
              ngân hàng thụ hưởng và nội dung chuyển khoản quý khách điền TÊN
              ĐĂNG NHẬP của mình nhé . Ngoài các tài khoản thụ hưởng không phải
              lấy từ trang chủ bên em sẽ không được hỗ trợ{" "}
              {settings && settings.name_page} RA MẮT【GAME NỔ HỦ MỚI PG
              SLOT】： {settings && settings.name_page} theo phương châm khách
              hàng là thượng đế, để không phụ lòng phản hồi của khách hàng khi
              tham gia cược game slot thiếu 2 sảnh này, hôm nay{" "}
              {settings && settings.name_page} chính thức ra mắt sảnh【GAME NỔ
              HỦ MỚI PG SLOT】mục đích đáp ứng để cho khách hàng được nhiều sự
              lựa chọn hơn khi tham gia game slot, cháy với đam mê !{" "}
              {settings && settings.name_page}
              không dừng lại ở đó hứa hẹn sẽ cho ra nhiều sảnh game hot, khuyến
              mãi siêu hấp dẫn và chất lượng dịch vụ tốt hơn ! Cảm ơn quý khách
              đã luôn đồng hành cùng {settings && settings.name_page} trong suốt
              thời gian qua ! ***CHÚC QUÝ KHÁCH THAM GIA GAME VUI VẺ VÀ CƯỢC
              NHIỀU MAY MẮN***
              {/* THÔNG BÁO THAY ĐỔI ĐƯỜNG LINK TRUY CẬP： Kênh VIP 【55{settings && settings.name_page}.com】
              【66{settings && settings.name_page}.com】 【77{settings && settings.name_page}.com】 【88{settings && settings.name_page}.com】
              【99{settings && settings.name_page}.com】 TÊN MIỀN VĨNH VIỄN【{settings && settings.name_page}.com】【qh99.com】
              Link Mở Thưởng【kqxs77.com】 Link tải APP 【qh92.com】 Dịch vụ
              CSKH【qh01.vip】 ĐẠI SẢNH NHẬN KHUYẾN MÃI NHANH 【qh183.com】 */}
              Quý khách vui lòng lưu lại địa chỉ truy cập trang web ở trên để
              tránh bỏ lỡ mà không tìm thấy chúng tôi nhé! THÂN GỬI QUÝ KHÁCH
              HÀNG THÂN MẾN： Thời gian quay số mở thưởng là thời gian nạp tiền
              cao điểm , để tránh làm lỡ việc đặt cược của quý khách, quý khách
              vui lòng nạp trước giờ cao điểm nhé !Xin chân thành cảm ơn sự hợp
              tác của quý khách! LINK CSKH HỖ TRỢ TRỰC TUYẾN 24/7： Quý khách
              thân mến ! Nếu quý khách trong quá trình trải nghiệm gặp phải bất
              kỳ thắc mắc nào có thể truy cập hoặc trực tiếp nhấp vào đường link
              CSKH hỗ trợ trực tuyến 24/7 , {settings && settings.name_page} xin
              chúc quý khách may mắn !
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewsRun;
