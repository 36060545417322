import React, { useEffect, useRef, useState } from 'react';
import { cachchoi } from '../../../img/importImg';
import 'react-toastify/dist/ReactToastify.css';
import { chuSo, listTheLoai } from '../../../dummy';
import { Bet, BetTxcl } from '.';
import BetItem from './BetItem';

export default function BetContent({ id, sendMsg, data }) {
    const [listTheLoaiNew, setListTheLoaiNew] = useState(listTheLoai);
    const [theloai, setTheLoai] = useState('txcl');
    const [listType, setListType] = useState(listTheLoaiNew[0].listType);
    const [type, setType] = useState('lo2');
    const [kieuNhap, setKieuNhap] = useState('chonso');
    const [isHidden, setIsHidden] = useState(false);
    const [contentCachChoi, setContentCachChoi] = useState(listTheLoaiNew[0].listType[0]);
    const [cacSoCuoc, setCacSoCuoc] = useState();
    const [isHiddenTram, setIsHiddenTram] = useState(false);
    const [isHiddenChuc, setIsHiddenChuc] = useState(false);
    const [isHiddenDonVi, setIsHiddenDonVi] = useState(false);
    const [tram, setTram] = useState([]);
    const [chuc, setChuc] = useState([]);
    const [donVi, setDonVi] = useState([]);
    const [isRender, setIsRender] = useState(true);
    const [daChon, setDaChon] = useState(0);
    const [soNhan, setSoNhan] = useState(1);
    const [thongTinDatCuoc, setThongTinDatCuoc] = useState('');
    const textareaRef = useRef(null);
    const playExplainRef = useRef(null);
    const [tiLe, setTiLe] = useState();
    const [minCuoc, setMinCuoc] = useState();

    useEffect(() => {
        if (tiLe && minCuoc) {
            const newListTheLoai = listTheLoaiNew.map((theLoai) => {
                const newTypeList = theLoai.listType.map((type) => {
                    return {
                        ...type,
                        tiLe: tiLe[type.value],
                        minCuoc: minCuoc[type.value],
                    };
                });

                return {
                    ...theLoai,
                    listType: newTypeList,
                };
            });

            setListTheLoaiNew(newListTheLoai);
            setListType(newListTheLoai[0].listType);
            setContentCachChoi(newListTheLoai[0].listType[0]);
            setIsRender(!isRender);
        }
    }, [tiLe]);

    useEffect(() => {
        if (data?.tile) {
            setTiLe(data.tile[0]);
            setMinCuoc(data.tile[1]);
        }
    }, [data]);

    useEffect(() => {
        if (listType) {
            setType(listType[0].value);
        }
    }, [theloai]);

    useEffect(() => {
        listType.forEach((item) => {
            if (item.value === type) {
                setContentCachChoi(item);
            }
        });

        if (type === 'lo2' || type === 'de' || type === 'daude') {
            setIsHiddenTram(true);
            setIsHiddenChuc(false);
            setIsHiddenDonVi(false);
        } else if (type === 'dau') {
            setIsHiddenTram(true);
            setIsHiddenChuc(false);
            setIsHiddenDonVi(true);
        } else if (type === 'duoi') {
            setIsHiddenTram(true);
            setIsHiddenChuc(true);
            setIsHiddenDonVi(false);
        } else if (type === '3cang') {
            setIsHiddenTram(false);
            setIsHiddenChuc(false);
            setIsHiddenDonVi(false);
        }

        setTram([]);
        setChuc([]);
        setDonVi([]);
        setDaChon(0);
        setSoNhan(1);
        setThongTinDatCuoc('');
    }, [type]);

    useEffect(() => {
        if (listType[0].value === 'xien2') {
            setKieuNhap('nhapso');
            setIsHidden(true);
        } else setIsHidden(false);
    }, [listType[0].value]);

    const handleTheLoai = (category) => {
        setTheLoai(category);
        listTheLoaiNew.forEach((item) => {
            if (item.value === category) {
                setListType(item.listType);
            }
        });
    };

    const handleType = (typeNew) => {
        setType(typeNew);
    };

    const handlePlayExplainClick = () => {
        textareaRef.current.focus();
        playExplainRef.current.classList.add('hidden');
    };

    const handleTram = (valueTram) => {
        setIsRender(!isRender);
        const listTram = tram;
        if (listTram.includes(valueTram)) {
            const listTramNew = listTram.filter((item) => item !== valueTram);
            setTram(listTramNew);
        } else {
            listTram.push(valueTram);
            setTram(listTram);
        }

        let newDaChon = 0;
        if (type === 'lo2' || type === 'de' || type === 'daude') {
            newDaChon = chuc.length * donVi.length;
        } else if (type === 'dau') {
            newDaChon = chuc.length;
        } else if (type === 'duoi') {
            newDaChon = donVi.length;
        } else if (type === '3cang') {
            newDaChon = tram.length * chuc.length * donVi.length;
        }

        setDaChon(newDaChon);

        if (type === '3cang') {
            if (tram.length > 0 && chuc.length > 0 && donVi.length > 0) {
                let ketQua = [];

                for (let i = 0; i < tram.length; i++) {
                    for (let j = 0; j < chuc.length; j++) {
                        for (let k = 0; k < donVi.length; k++) {
                            ketQua.push(tram[i] + '' + chuc[j] + '' + donVi[k]);
                        }
                    }
                }

                const ketQuaChuoi = ketQua.join(',');

                setThongTinDatCuoc(ketQuaChuoi);
            }
        }
    };

    const handleChuc = (valueChuc) => {
        setIsRender(!isRender);
        const listChuc = chuc;
        if (listChuc.includes(valueChuc)) {
            const listChucNew = listChuc.filter((item) => item !== valueChuc);
            setChuc(listChucNew);

            if (type === 'dau') {
                const ketQuaChuoi = listChucNew.join(',');
                setThongTinDatCuoc(ketQuaChuoi);
            }
        } else {
            listChuc.push(valueChuc);
            setChuc(listChuc);

            if (type === 'dau') {
                const ketQuaChuoi = listChuc.join(',');
                setThongTinDatCuoc(ketQuaChuoi);
            }
        }

        let newDaChon = 0;
        if (type === 'lo2' || type === 'de' || type === 'daude') {
            newDaChon = chuc.length * donVi.length;
        } else if (type === 'dau') {
            newDaChon = chuc.length;
        } else if (type === 'duoi') {
            newDaChon = donVi.length;
        } else if (type === '3cang') {
            newDaChon = tram.length * chuc.length * donVi.length;
        }

        setDaChon(newDaChon);

        if (type === 'lo2' || type === 'de' || type === 'daude') {
            if (chuc.length > 0 && donVi.length > 0) {
                let ketQua = [];
                for (let i = 0; i < chuc.length; i++) {
                    for (let j = 0; j < donVi.length; j++) {
                        ketQua.push(chuc[i] + '' + donVi[j]);
                    }
                }
                const ketQuaChuoi = ketQua.join(',');

                setThongTinDatCuoc(ketQuaChuoi);
            }
        }

        if (type === '3cang') {
            if (tram.length > 0 && chuc.length > 0 && donVi.length > 0) {
                let ketQua = [];

                for (let i = 0; i < tram.length; i++) {
                    for (let j = 0; j < chuc.length; j++) {
                        for (let k = 0; k < donVi.length; k++) {
                            ketQua.push(tram[i] + '' + chuc[j] + '' + donVi[k]);
                        }
                    }
                }

                const ketQuaChuoi = ketQua.join(',');

                setThongTinDatCuoc(ketQuaChuoi);
            }
        }
    };

    const handleDonVi = (valueDonVi) => {
        setIsRender(!isRender);
        const listDonVi = donVi;
        if (listDonVi.includes(valueDonVi)) {
            const listDonViNew = listDonVi.filter((item) => item !== valueDonVi);
            setDonVi(listDonViNew);
            // console.log(listDonViNew);

            if (type === 'duoi') {
                const ketQuaChuoi = listDonViNew.join(',');
                setThongTinDatCuoc(ketQuaChuoi);
            }
        } else {
            listDonVi.push(valueDonVi);
            setDonVi(listDonVi);

            if (type === 'duoi') {
                const ketQuaChuoi = listDonVi.join(',');
                setThongTinDatCuoc(ketQuaChuoi);
            }
        }

        let newDaChon = 0;
        if (type === 'lo2' || type === 'de' || type === 'daude') {
            newDaChon = chuc.length * donVi.length;
        } else if (type === 'dau') {
            newDaChon = chuc.length;
        } else if (type === 'duoi') {
            newDaChon = donVi.length;
        } else if (type === '3cang') {
            newDaChon = tram.length * chuc.length * donVi.length;
        }

        setDaChon(newDaChon);

        if (type === 'lo2' || type === 'de' || type === 'daude') {
            if (chuc.length > 0 && donVi.length > 0) {
                let ketQua = [];
                for (let i = 0; i < chuc.length; i++) {
                    for (let j = 0; j < donVi.length; j++) {
                        ketQua.push(chuc[i] + '' + donVi[j]);
                    }
                }
                const ketQuaChuoi = ketQua.join(',');

                setThongTinDatCuoc(ketQuaChuoi);
            }
        }

        if (type === '3cang') {
            if (tram.length > 0 && chuc.length > 0 && donVi.length > 0) {
                let ketQua = [];

                for (let i = 0; i < tram.length; i++) {
                    for (let j = 0; j < chuc.length; j++) {
                        for (let k = 0; k < donVi.length; k++) {
                            ketQua.push(tram[i] + '' + chuc[j] + '' + donVi[k]);
                        }
                    }
                }

                const ketQuaChuoi = ketQua.join(',');

                setThongTinDatCuoc(ketQuaChuoi);
            }
        }
    };

    const handleClickXoaTram = () => {
        setTram([]);
        setDaChon(0);
    };

    const handleClickXoaChuc = () => {
        setChuc([]);
        setDaChon(0);
    };

    const handleClickXoaDonVi = () => {
        setDonVi([]);
        setDaChon(0);
    };

    const changeTextArea = (e) => {
        setCacSoCuoc(e.target.value);
    };

    return (
        <>
            <div className="select-game-content">
                <div className="play-type">
                    <div className="cates">
                        <div className="cates_box">
                            <div className="cates_list">
                                {!id.includes('xsmn') && !id.includes('xsmt') && (
                                    <div
                                        className={`item hot ${theloai === 'txcl' ? 'active' : ''}`}
                                        onClick={() => handleTheLoai('txcl')}
                                    >
                                        Trò chơi đặc sắc
                                    </div>
                                )}
                                {listTheLoaiNew.map((item, i) => {
                                    return (
                                        <div
                                            className={`item ${item.isHot ? 'hot' : ''} ${
                                                theloai === item.value ? 'active' : ''
                                            }`}
                                            key={i}
                                            onClick={() => handleTheLoai(item.value)}
                                        >
                                            {item.title}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <div className="rule">
                            <div className="rule-block">
                                <img
                                    src={cachchoi}
                                    alt=""
                                />
                                <span>Cách chơi</span>
                            </div>
                        </div>
                    </div>

                    {theloai !== 'txcl' && (
                        <div className="plays-tabs">
                            <div className="plays BL">
                                <div className="list">
                                    {listType &&
                                        listType.map((item, i) => {
                                            return (
                                                <div
                                                    className={`item ${type === item.value ? 'active' : ''}`}
                                                    key={i}
                                                    onClick={() => handleType(item.value)}
                                                >
                                                    {item.title}
                                                </div>
                                            );
                                        })}
                                </div>
                            </div>
                            <div className="game-tabs">
                                <div className="item-warp">
                                    {!isHidden && (
                                        <div
                                            className={`item ${kieuNhap === 'chonso' ? 'active' : ''}`}
                                            onClick={() => setKieuNhap('chonso')}
                                        >
                                            <span>Chọn số</span>
                                        </div>
                                    )}
                                    <div
                                        className={`item ${kieuNhap === 'nhapso' ? 'active' : ''}`}
                                        onClick={() => setKieuNhap('nhapso')}
                                    >
                                        <span>Nhập số</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div className="plays">
                    {theloai === 'txcl' && !id.includes('xsmn') && !id.includes('xsmt') ? (
                        <BetTxcl
                            {...{
                                tiLe: tiLe,
                                setContentCachChoi,
                                setDaChon,
                                setThongTinDatCuoc,
                                thongTinDatCuoc,
                                contentCachChoi,
                            }}
                        />
                    ) : (
                        <Bet
                            {...{
                                kieuNhap,
                                isHiddenTram,
                                chuSo,
                                tram,
                                handleTram,
                                handleClickXoaTram,
                                isHiddenChuc,
                                chuc,
                                handleChuc,
                                handleClickXoaChuc,
                                isHiddenDonVi,
                                donVi,
                                handleDonVi,
                                handleClickXoaDonVi,
                                changeTextArea,
                                textareaRef,
                                cacSoCuoc,
                                handlePlayExplainClick,
                                playExplainRef,
                                contentCachChoi,
                            }}
                        />
                    )}
                </div>
            </div>
            <BetItem
                {...{
                    id,
                    sendMsg,
                    theloai,
                    contentCachChoi,
                    cacSoCuoc,
                    setCacSoCuoc,
                    setTram,
                    setChuc,
                    setDonVi,
                    isRender,
                    setIsRender,
                    daChon,
                    setDaChon,
                    soNhan,
                    setSoNhan,
                    thongTinDatCuoc,
                    setThongTinDatCuoc,
                    type,
                    kieuNhap,
                }}
            />
        </>
    );
}
