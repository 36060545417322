import React, { useEffect, useState } from 'react';
import { VND } from '../../../utils';
import { navbar } from '../../../components/Layout/Navbar/data';
import { iconNext, iconPrev } from '../../../img/importImg';
import { getHistoryBet } from '.';

export default function HistoryBet({ id, data, sendMsg, isAuth }) {
    const [historyByUser, setHistoryByUser] = useState();
    const [soTrang, setSoTrang] = useState(1);
    const [totalPage, setTotalPage] = useState(0);

    useEffect(() => {
        if (data?.[id]?.history) {
            const history = data[id].history;
            setHistoryByUser(history.data);
            setTotalPage(history.totalPage);
        }
    }, [data]);

    useEffect(() => {
        if (isAuth) getHistoryBet(sendMsg, id, soTrang);
    }, [soTrang]);

    return (
        <div className="betlog-wrap">
            <div className="bet-log">
                <div className="table-title">
                    <div className="more"> Tiền cược：0 Vnd , Tổng thắng thua：0 Vnd </div>
                    <div className="bet-tabs d-flex">
                        <div className="game-tabs">
                            <div className="item-warp">
                                <div className="item-tab item-tab-history active">Lịch sử</div>
                                {/* <div className="item-tab item-tab-winlose">
    Thắng thua
  </div>
  <div className="item-tab item-tab-pending">
    Chưa thanh toán
  </div> */}
                            </div>
                        </div>
                        {/* <div className="b-btn">Làm mới</div> */}
                    </div>
                </div>
                <div
                    className="item table-name row"
                    style={{ width: '100%', marginLeft: '1px' }}
                >
                    <div className="col-1">Loại XS</div>
                    <div className="col-1">Lượt xổ</div>
                    {/* <div className="col-1">Số thứ tự</div> */}
                    <div className="col-2">Thời gian</div>
                    <div className="col-1">Cách chơi</div>
                    <div className="col-2">Thông tin đặt cược</div>
                    <div className="col-1">Số đơn cược</div>
                    <div className="col-1">Cấp số nhân</div>
                    <div className="col-1">Tổng số tiền</div>
                    <div className="col-1">Thắng thua</div>
                    <div className="col-1">Trạng thái</div>
                </div>
                <div className="b-content">
                    {historyByUser ? (
                        historyByUser.map((item, i) => {
                            return (
                                <div
                                    className="b-content-item"
                                    key={i}
                                >
                                    <div className="item table-name row content-table">
                                        <div className="col-1">
                                            {item.theloai === 'xsmb'
                                                ? 'XSMB'
                                                : item.theloai.includes('xsmn')
                                                ? navbar.miennam[8].find((item) => item.link.includes(id))?.title
                                                : `ST ${item.theloai} Phút`}
                                        </div>
                                        <div className="col-1">{item.phien}</div>
                                        {/* <div className="col-1"></div> */}
                                        <div className="col-2">{item.date}</div>
                                        <div className="col-1">{item.cachChoi}</div>
                                        <div className="col-2">{item.so}</div>
                                        <div className="col-1">1</div>
                                        <div className="col-1">{item.amount}</div>
                                        <div className="col-1">{VND.format(Number(item.cuoc))}</div>
                                        <div className="col-1">{VND.format(Number(item.win))}</div>
                                        <div className="col-1">{item.thanhtoan == 1 ? 'Đã xử lý' : 'Chờ mở giải'}</div>
                                    </div>
                                </div>
                            );
                        })
                    ) : (
                        <div className="no-data">
                            <p className="more">Không có thêm dữ liệu</p>
                        </div>
                    )}
                </div>
                <div className="el-pagination is-background">
                    <button
                        className="btn-prev"
                        disabled={soTrang === 1}
                        onClick={() => setSoTrang(soTrang - 1)}
                    >
                        <img
                            src={iconPrev}
                            alt=""
                            style={{ width: '8px' }}
                        />
                    </button>
                    <ul className="el-pager">
                        <li className="number active">{soTrang}</li>
                    </ul>
                    <button
                        className="btn-next"
                        disabled={soTrang + 1 > totalPage}
                        onClick={() => setSoTrang(soTrang + 1)}
                    >
                        <img
                            src={iconNext}
                            alt=""
                            style={{ width: '8px' }}
                        />
                    </button>
                </div>
                <div className="el-dialog__wrapper"></div>
            </div>
        </div>
    );
}
