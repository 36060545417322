import React, { useState } from 'react';
import { twMerge } from 'tailwind-merge';
const txcl = [
    { label: 'Tài', value: 'tai' },
    { label: 'Xỉu', value: 'xiu' },
    { label: 'Chẵn', value: 'chan' },
    { label: 'Lẻ', value: 'le' },
];
const number = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

const content = [
    { label: 'Đầu', value: 1 },
    { label: 'Hai', value: 2 },
    { label: 'Ba', value: 3 },
    { label: 'Bốn', value: 4 },
    { label: 'Năm', value: 5 },
];

const classActive = 'bg-blue-500 text-white';

const checkActive = ({ value }, txt = '', bet, line) => {
    return value === txt && bet === line ? classActive : '';
};

export default function BetSpecial({
    tiLe,
    setContentCachChoi,
    setDaChon,
    setThongTinDatCuoc,
    contentCachChoi,
    thongTinDatCuoc,
}) {
    const handleBet = (title, bet, so_don = {}) => {
        const { value, label } = bet;
        const { value: valueSodon, label: labelSodon } = so_don;
        const type2 = title === 'tong' && !valueSodon ? 'tong' : valueSodon;
        const type = (isNaN(value) && !valueSodon ? 'txcl-' : 'so_don-') + type2;
        title = title === 'tong' && !valueSodon ? 'Tổng' : `Số ${labelSodon}`;
        const rateTxcl = tiLe?.t_x_c_l || 0;
        const rateSoDon = tiLe?.so_don || 0;
        const rate = isNaN(value || valueSodon) ? rateTxcl : rateSoDon;
        setContentCachChoi({
            title,
            minCuoc: 1000,
            tiLe: rate,
            value: type,
        });
        setDaChon(1);
        setThongTinDatCuoc(label || bet);
        // const dataCuoc = {
        //     // theloai: "1",
        //     so: thongTinDatCuoc,
        //     sodiem: soNhan,
        //     type: contentCachChoi.value,
        // };
        // parentCallbackDatCuoc(dataCuoc);
    };

    return (
        <div className="m-8 bg-gray-200 rounded-lg">
            <div className="p-4">
                <h3 className="text-2xl pb-4">Tổng</h3>
                <div className="flex justify-between gap-4">
                    {txcl.map((item) => (
                        <div
                            key={item.value}
                            className={twMerge(
                                'flex justify-center w-full bg-white rounded-lg py-2 text-3xl cursor-pointer relative',
                                checkActive(contentCachChoi, `txcl-tong`, thongTinDatCuoc, item.label)
                            )}
                            onClick={() => handleBet('tong', item)}
                        >
                            <p>{item.label}</p>
                            <p className="absolute top-0 right-0 text-xl p-2">{(tiLe?.t_x_c_l || 0) / 1000}</p>
                        </div>
                    ))}
                </div>
            </div>
            {content.map((item) => (
                <div
                    key={item.value}
                    className="p-4"
                >
                    <h3 className="text-2xl pb-4">Số {item.label}</h3>
                    <div className="grid grid-cols-4 gap-4">
                        {txcl.map((txcl) => (
                            <div
                                key={txcl.value}
                                className={twMerge(
                                    'flex justify-center w-full bg-white rounded-lg py-2 text-3xl cursor-pointer relative',
                                    checkActive(contentCachChoi, `so_don-${item.value}`, thongTinDatCuoc, txcl.label)
                                )}
                                onClick={() => handleBet('tong', txcl, item)}
                            >
                                <p>{txcl.label}</p>
                                <p className="absolute top-0 right-0 text-xl p-2">{(tiLe?.t_x_c_l || 0) / 1000}</p>
                            </div>
                        ))}
                    </div>
                    <div className="grid grid-cols-5 gap-4 pt-8">
                        {number.map((number) => (
                            <div
                                key={number}
                                className={twMerge(
                                    'flex justify-center w-full bg-white rounded-lg py-2 text-3xl cursor-pointer relative',
                                    checkActive(contentCachChoi, `so_don-${item.value}`, thongTinDatCuoc, number)
                                )}
                                onClick={() => handleBet(item.value, number, item)}
                            >
                                <p>{number}</p>
                                <p className="absolute top-0 right-0 text-xl p-2">{(tiLe?.so_don || 0) / 1000}</p>
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );
}
